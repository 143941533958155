import { CustomDetailCellRendererProps } from '@ag-grid-community/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../store'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getSecurityStaticDataById } from '../../store/securities/selectors'
import {
  getCanEnterPassiveOrders,
  getHasTradingRights
} from '../../store/webSettings/selectors'
import styles from './Depth/DepthOfMarket.module.scss'
import { PassiveDepthOfMarket } from './PassiveDepthOfMarket'

const DepthDetailRenderer = ({
  data,
  pinned,
  context
}: CustomDetailCellRendererProps<SecurityStaticData>) => {
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const canEnterPassiveOrders = useAppSelector(getCanEnterPassiveOrders)
  // grid doesn't get updates for performance reasons--get latest security
  const security = useSelector(getSecurityStaticDataById)(data?.id ?? -1)

  if (!security || pinned) return null
  return (
    <div className={styles.depthDetail}>
      <PassiveDepthOfMarket
        security={security}
        gridIndex={context?.gridIndex ?? 0}
        isPopout={false}
        showOrderEntry={hasTradingRights && canEnterPassiveOrders}
      />
    </div>
  )
}

export default DepthDetailRenderer
