import { LoginCallback } from '@okta/okta-react'
import { withProfiler } from '@sentry/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useHref, useNavigate } from 'react-router'
import { useResetStoredSettings } from '../helpers/hooks/useResetStoredSettings.ts'

import AdminRouteParent from '../routing/Admin/AdminRouteParent'
import ProtectedRouteParent from '../routing/ProtectedRouteParent'

import ErrorReporter from '../routing/ErrorReporter'

// NON-ADMIN ROUTES
import ActivityLogGrid from '../components/ActivityLog/Grid/ActivityLogGrid'
import ActivityTradeBlotterGrid from '../components/ActivityTradeBlotter/Grid/ActivityTradeBlotterGrid'
import AlertFilterEditor from '../components/AlertFilter/AlertFilterEditor'
import BenchmarkDataContainer from '../components/BenchmarkData/BenchmarkContainer'
import SecuritiesTable from '../components/SecuritiesTable'
import SettingsModal from '../components/SettingsModal/SettingsModal'

// // PLATFORM ONLY
// import OpenfinFrame from '../containers/Openfin/OpenfinFrame'

// ADMIN ROUTES
import ActivityTicker from '../components/Admin/ActivityTicker/ActivityTicker'
import TradeBlotter from '../components/Admin/AdminTradeBlotter/AdminTradeBlotter'
import AggressAttemptsGrid from '../components/Admin/AggressAttempts/Grid/AggressAttemptsGrid'
import BBMISIN from '../components/Admin/BBMISIN/BBMISIN'
import ExternalLiquidityStats from '../components/Admin/ExternalLiquidityStats/ExternalLiquidityStats'
import ExternalOrderStats from '../components/Admin/ExternalOrderStats/ExternalOrderStats'
import HistoricalUserData from '../components/Admin/HistoricalUserData/HistoricalUserData'
import ManagementReportMainPage from '../components/Admin/ManagementReports/ManagementReportMainPage'
import QuerySelector from '../components/Admin/Query/QuerySelector'
import QueueStats from '../components/Admin/QueueStats/QueueStats'
import RestrictedTrades from '../components/Admin/RestrictedTrades/RestrictedTrades'
import SystemParameters from '../components/Admin/SystemParameters/SystemParameters'
import Tiers from '../components/Admin/Tiers/Tiers'
import UserDashboard from '../components/Admin/UserDashboard/UserDashboard'
import WatchlistManager from '../components/Admin/WatchlistManager/WatchlistManager'
import CookieNotice from '../components/CookieNotice/CookieNotice'
import Header from '../components/Header/Header'
import Logger from '../components/Logger/Logger'
import PassiveOrders from '../components/PassiveOrders/PassiveOrders'

// POPOUTS
import { UserEditorPopoutHost } from '../containers/Openfin/Admin/UserEditorPopout'
import { DepthPopoutHost } from '../containers/Openfin/Depth'
import { WatchlistPopoutHost } from '../containers/Openfin/Watchlist'
import { PopoutHost } from '../containers/Popouts/Popout'

import { addLogItem } from '../store/log/actions'
import {
  getCanMarkRestricted,
  getCanSeeSystemParamEditor,
  getIsAdmin
} from '../store/webSettings/selectors'
import { useOpenFin } from './openFinContext'
import { getUseLightTheme } from '../store/userPreferences/selectors'

type ScreenName =
  | 'PassiveOrders'
  | 'ActivityBlotter'
  | 'ActivityLog'
  | 'AggressAttempts'
  | 'Tiers'
  | 'SystemParameters'
  | 'QueueStats'
  | 'ManagementReports'
  | 'BBMISIN'
  | 'TradeBlotter'
  | 'QuerySelector'
  | 'ActivityTicker'
  | 'UserDashboard'
  | 'RestrictedTrading'
  | 'RestrictedTradingPermanent'
  | 'GUIUserData'
  | 'APIUserData'
  | 'ExternalLiquidityStats'
  | 'WatchlistManager'

const App = () => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  const canSeeSystemParamEditor = useSelector(getCanSeeSystemParamEditor)
  const canSeeRestrictMenu = useSelector(getCanMarkRestricted)
  const useLightTheme = useSelector(getUseLightTheme)
  const { fin, manageWindows } = useOpenFin()

  const externalOrderStatsUrl = useHref('/admin/APIUserData')
  const externalLiquidityStatsUrl = useHref('/admin/ExternalLiquidityStats')
  const historicalUserDataUrl = useHref('/admin/GUIUserData')

  useResetStoredSettings(true)

  const documentClick = (e: MouseEvent) => {
    // @ts-ignore
    const testId = e.target?.getAttribute('data-testid')

    if (testId) {
      const st = 'click ' + testId
      dispatch(addLogItem(st))
    }
  }

  const documentKeyUp = (e: KeyboardEvent) => {
    if (e.target instanceof Element) {
      const testId = e.target?.getAttribute('data-testid') || ''
      const st = 'KeyUp ' + e.key + ' ' + testId
      // console.log(st)
      dispatch(addLogItem(st))
    }
  }

  useEffect(() => {
    document.addEventListener('click', (e) => documentClick(e))
    document.addEventListener('keyup', (e) => documentKeyUp(e))
    return () => {
      document.removeEventListener('click', (e) => documentClick(e))
      document.removeEventListener('keyup', (e) => documentKeyUp(e))
    }
  }, [])

  useEffect(() => {
    useLightTheme
      ? document.body.classList.add('lightTheme')
      : document.body.classList.remove('lightTheme')
  }, [useLightTheme])

  const navigate = useNavigate()

  const goToScreen = useCallback(
    (screen: ScreenName) => {
      switch (screen) {
        // non admin
        case 'PassiveOrders':
        case 'ActivityBlotter':
        case 'ActivityLog':
          navigate(`/${screen}`)
          break
        case 'AggressAttempts':
        case 'Tiers':
        case 'QueueStats':
        case 'ManagementReports':
        case 'BBMISIN':
        case 'TradeBlotter':
        case 'QuerySelector':
        case 'ActivityTicker':
        case 'WatchlistManager':
        case 'UserDashboard':
          /* intentionally falls through */
          navigate(`/admin/${screen}`)
          break
        // more strict
        case 'SystemParameters':
          if (canSeeSystemParamEditor) {
            navigate(`/admin/${screen}`)
          }
          break
        case 'RestrictedTrading':
        case 'RestrictedTradingPermanent':
          if (canSeeRestrictMenu) {
            navigate(`/admin/${screen}`)
          }
          break
        case 'APIUserData':
          window.open(externalOrderStatsUrl)
          break
        case 'ExternalLiquidityStats':
          window.open(externalLiquidityStatsUrl)
          break
        case 'GUIUserData':
          window.open(historicalUserDataUrl)
          break
        default:
          navigate('')
      }
    },
    [canSeeSystemParamEditor, canSeeRestrictMenu]
  )

  return (
    <>
      {!fin && <Header setSelectedTab={goToScreen} isAdmin={isAdmin} />}
      <Logger />
      <Routes>
        <Route
          path="/implicit/callback"
          element={<LoginCallback errorComponent={ErrorReporter} />}
        />

        <Route path="/" element={<ProtectedRouteParent />}>
          <Route index element={<SecuritiesTable />} />
          <Route path="/PassiveOrders" element={<PassiveOrders />} />
          <Route
            path="/ActivityBlotter"
            element={<ActivityTradeBlotterGrid />}
          />
          <Route path="/ActivityLog" element={<ActivityLogGrid />} />

          {/* openfin routes */}
          <>
            <Route path="/Aggressor/:id" element={<PopoutHost />} />
            <Route
              path="/AlertManager"
              element={
                <AlertFilterEditor
                  closeFunc={() => manageWindows('AlertManager', 'hide')}
                />
              }
            />
            <Route path="/Benchmarks" element={<BenchmarkDataContainer />} />
            <Route path="/Depth/:id" element={<DepthPopoutHost />} />
            <Route path="/LastLook/:id" element={<PopoutHost />} />
            <Route
              path="/Settings"
              element={
                <SettingsModal
                  closeFunc={() => manageWindows('Settings', 'close')}
                />
              }
            />
            <Route path="/Watchlist/:id" element={<WatchlistPopoutHost />} />

            {/* TODO: page doesn't load properly when moving this to the admin section */}
            <Route
              path="/admin/UserEditor"
              element={<UserEditorPopoutHost />}
            />
          </>

          <Route path="/admin" element={<AdminRouteParent />}>
            <Route
              path="AggressAttempts"
              element={<AggressAttemptsGrid setSelectedTab={goToScreen} />}
            />
            <Route path="Tiers" element={<Tiers />} />
            <Route path="SystemParameters" element={<SystemParameters />} />
            <Route path="QueueStats" element={<QueueStats />} />
            <Route
              path="ManagementReports"
              element={<ManagementReportMainPage />}
            />
            <Route path="BBMISIN" element={<BBMISIN />} />
            <Route path="TradeBlotter" element={<TradeBlotter />} />
            <Route path="QuerySelector" element={<QuerySelector />} />
            <Route path="ActivityTicker" element={<ActivityTicker />} />
            <Route path="UserDashboard" element={<UserDashboard />} />
            <Route
              path="RestrictedTrading"
              element={<RestrictedTrades selectedTab={'temp'} />}
            />
            <Route
              path="RestrictedTradingPermanent"
              element={<RestrictedTrades selectedTab={'Permanent'} />}
            />
            <Route path="GUIUserData" element={<HistoricalUserData />} />
            <Route path="APIUserData" element={<ExternalOrderStats />} />
            <Route
              path="ExternalLiquidityStats"
              element={<ExternalLiquidityStats />}
            />
            <Route path="WatchlistManager" element={<WatchlistManager />} />
          </Route>
        </Route>
      </Routes>
      {!fin && <CookieNotice />}
    </>
  )
}

export default withProfiler(App, { name: 'Bonds.com React app' })
