import {
  faAnglesDown,
  faAnglesRight,
  faClose,
  faCompressAlt,
  faWindowMinimize
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import ComponentHeader from '../../components/ComponentHeader/ComponentHeader'
import AdminTools from '../../components/DepthOfMarket/Depth/AdminTools'
import { PassiveDepthOfMarket } from '../../components/DepthOfMarket/PassiveDepthOfMarket'
import { SecurityStaticData } from '../../store/securities/reducer'
import {
  getCanEnterPassiveOrders,
  getHasTradingRights
} from '../../store/webSettings/selectors'
import { setWindowFront } from '../../store/windows/actions'
import { IsInPopupContext } from '../popoutContext'
import PopoutDepthHeader from './Title/PopoutDepthHeader'

import { useOpenFin } from '../../app/openFinContext'
import headerIconStyles from '../../components/ComponentHeader/ComponentHeader.module.scss'
import { useAppDispatch, useAppSelector } from '../../store'
import { closeAllPopouts, closePopout } from '../../store/depthOfMarket/actions'
import styles from './depthPopout.module.scss'

interface Props {
  security: SecurityStaticData
  gridIndex: number
  isAdmin: boolean
  showCloseAll: boolean
}

const adminExtraColumns = [AdminTools]

const PopoutDepthOfMarket = ({
  security,
  gridIndex,
  isAdmin,
  showCloseAll
}: Props) => {
  const extraColumns = isAdmin ? adminExtraColumns : undefined
  const dispatch = useAppDispatch()
  const { fin, manageWindows } = useOpenFin()
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const canEnterPassiveOrders = useAppSelector(getCanEnterPassiveOrders)
  const [showOrderEntry, setShowOrderEntry] = useState(
    hasTradingRights && canEnterPassiveOrders
  )

  const classes = cx(styles.depthOpenerPopout, {
    [styles.isFin]: !!fin,
    [styles.showOrderEntry]: showOrderEntry
  })

  const moveWindowFront = useCallback(() => {
    dispatch(setWindowFront('depth'))
  }, [])

  const headerButtons = useMemo(() => {
    return (
      <>
        {showCloseAll && (
          <div
            className={headerIconStyles.icon}
            onClick={() => dispatch(closeAllPopouts())}
            title="Close All Popouts"
          >
            <FontAwesomeIcon icon={faCompressAlt} />
          </div>
        )}

        {hasTradingRights && canEnterPassiveOrders && (
          <div
            className={headerIconStyles.icon}
            onClick={() => setShowOrderEntry(!showOrderEntry)}
            title={`${showOrderEntry ? 'Hide' : 'Show'} Order Entry`}
          >
            <FontAwesomeIcon
              icon={showOrderEntry ? faAnglesDown : faAnglesRight}
            />
          </div>
        )}
        {fin && (
          <div
            className={headerIconStyles.icon}
            onClick={() => manageWindows(`Depth/${security.id}`, 'minimize')}
            title="Minimize"
          >
            <FontAwesomeIcon icon={faWindowMinimize} />
          </div>
        )}
        <div
          className={headerIconStyles.icon}
          onClick={() => dispatch(closePopout(security.id))}
          title="Close Depth"
        >
          <FontAwesomeIcon icon={faClose} />
        </div>
      </>
    )
  }, [showOrderEntry, showCloseAll])

  if (!security) {
    return <></>
  }

  return (
    <IsInPopupContext.Provider value={true}>
      <div onClick={moveWindowFront} className={styles.depthWrapper}>
        <ComponentHeader
          title={`Depth/${security.id}`}
          headerButtons={headerButtons}
        >
          <PopoutDepthHeader security={security} />
        </ComponentHeader>
        <div className={classes}>
          <PassiveDepthOfMarket
            security={security}
            gridIndex={gridIndex}
            isPopout={true}
            extraColumns={extraColumns}
            showOrderEntry={showOrderEntry}
          />
        </div>
      </div>
    </IsInPopupContext.Provider>
  )
}

PopoutDepthOfMarket.displayName = 'PopoutDepthOfMarket'

export default PopoutDepthOfMarket
